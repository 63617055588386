<template>
  <div>
    <b-row class="mb-2">
      <b-col md="12" style="display: flex; gap: 10px">
        <export-button
          :filter="exportFilter"
          url="/export-center-horses-history"
          :disabled="loadingData || !items.length"
          file-title="Horses report.xlsx"
        />
        <b-button v-b-modal.modal-hierachy v-if="width < 768">
          <feather-icon icon="GitPullRequestIcon" />
        </b-button>
      </b-col>
    </b-row>
    <b-card>
      <!-- FILTER START -->
      <b-row>
        <b-col md="3">
          <b-form-group :label="$t('labels.transaction_id')">
            <b-form-input
              v-model="filterSelect.transactionId"
              @input="inputSelect"
              :placeholder="$t('labels.transaction_id')"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group :label="$t('labels.typeuser')">
            <v-select
              :value="$store.value"
              v-model="filterSelect.userType"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="typeOptions"
              label="key"
              @input="inputSelect"
              :reduce="(status) => status.value"
              placeholder="Selecciona un Tipo"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group :label="$t('labels.status')">
            <v-select
              :value="$store.key"
              v-model="filterSelect.transaction_status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              label="value"
              @input="inputSelect"
              :reduce="(status) => status.key"
              placeholder="Selecciona un Status"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group :label="$t('labels.datefrom')" rules="required">
            <flat-pickr
              v-model="filterSelect.dateFrom"
              @input="inputSelect"
              class="form-control"
              :config="{
                altInput: true,
                altFormat: 'd-m-Y h:i K',
                enableTime: true,
                wrap: true,
                plugins: [
                  new yearDropdownPlugin({
                    yearStart: 100,
                    yearEnd: 0,
                  }),
                ],
              }"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group :label="$t('labels.dateto')" rules="required">
            <flat-pickr
              v-model="filterSelect.dateTo"
              @input="inputSelect"
              class="form-control"
              :config="{
                altInput: true,
                altFormat: 'd-m-Y h:i K',
                enableTime: true,
                wrap: true,
                plugins: [
                  new yearDropdownPlugin({
                    yearStart: 100,
                    yearEnd: 0,
                  }),
                ],
              }"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- FILTER END -->
    </b-card>
    <b-row>
      <b-col md="10">
        <b-card>
          <b-overlay :show="loadingData" variant="transparent" no-wrap />
          <b-modal
            id="modalResponse"
            title="PopUp Response"
            cancel-variant="outline-secondary"
            scrollable
          >
            <b-card-text>
              {{ rows }}
            </b-card-text>
          </b-modal>

          <!-- search -->
          <b-col md="3" class="d-flex align-items-center justify-content-center mb-1">
            <b-form-input
              v-model="search"
              class="d-inline-block mr-1"
              placeholder="Filtrar"
            />
          </b-col>
          <b-table md="9" :items="dataItems" :fields="fields" class="scroll" responsive>
            <template #cell(referenceId)="data">
              <div class="mr-1 text-center">
                <b-button
                 
                  :to="{
                    name: 'apps-reports-liveBetting-detail',
                    params: { id: data.item.transactionID, userId: dataIdUser, status: data.item.transaction_status },
                  }"
                  variant="outline-primary"
                  >{{ data.item.referenceId }}</b-button
                >

                
              </div>
            </template>
            <template #cell(amount)="data">
              {{ data.item.amount | currency({ symbol: "" }) }}
            </template>
            <template #cell(amountPrize)="data">
              {{ data.item.amountPrize | currency({ symbol: "" }) }}
            </template>
            <template #cell(amountRetired)="data">
              {{ data.item.amountRetired | currency({ symbol: "" }) }}
            </template>
            <template #cell(createdAt)="data">
              {{ data.item.createdAt | moment }}
            </template>
            <template #cell(transaction_status)="data">
                <span
                  v-if="data.item.transaction_status == 'bet'"
                  style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #03a9f4; color:#FFFFFF;"
                >
                  {{ $t('status.bet') }}</span
                >
                <span
                  v-else-if="data.item.transaction_status == 'return'"
                  style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #8bc34a; color:#FFFFFF;"
                >
                  {{ $t('status.return') }}</span
                >
                <span
                  v-else-if="data.item.transaction_status == 'bet_cancel'"
                  style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #f44336; color:#FFFFFF;"
                >
                  {{ $t('status.bet_cancel') }}</span
                >
                <span
                  v-else-if="data.item.transaction_status == 'cashout'"
                  style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #f44336; color:#FFFFFF;"
                >
                  {{ $t('status.cashout') }}</span
                >
                <span
                  v-else-if="data.item.transaction_status == 'lose'"
                  style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #f44336; color:#FFFFFF;"
                >
                  {{ $t('status.lose') }}</span
                >
                <span
                  v-else-if="data.item.transaction_status == 'win'"
                  style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #8bc34a; color:#FFFFFF;"
                >
                  {{ $t('status.win') }}</span
                >
                <span
                  v-else-if="data.item.transaction_status == 'cancel'"
                  style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #f44336; color:#FFFFFF;"
                >
                  {{ $t('status.cancel') }}</span
                >
                <span
                  v-else-if="data.item.transaction_status == 'rollback'"
                  style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #f44336; color:#FFFFFF;"
                >
                  {{ $t('status.rollback') }}</span
                >
              </template>
          </b-table>
          <b>TOTALES</b>
          <!-- <b-pagination
              v-model="page"
              :total-rows="totalRows"
              :per-page="limit"
              :simple="true"
              :hide-goto-end-buttons="true"
            ></b-pagination> -->
        </b-card>
      </b-col>
      <b-col class="p-0" md="2">
        <b-modal
          no-body
          id="modal-hierachy"
          hide-footer
          title="Listado de agentes"
          v-if="width < 768"
        >
          <HierarchyUsers @selectUser="showData" ref="HerarchiComponent" />
        </b-modal>
        <b-card no-body class="p-0 py-1" v-else>
          <HierarchyUsers @selectUser="showData" />
        </b-card>
        <b-modal no-body ref="my-modal" :title="$t('detail_ticket')" hide-footer>
          <b-card-text
            style="display: flex; justify-content: center; padding: 20px"
            v-if="img"
          >
            <iframe style="width: 300px; height: 400px" :srcdoc="img"></iframe>
          </b-card-text>
          <b-card-text v-else>
            <center>
              <h1>NO TICKET</h1>
            </center>
          </b-card-text>
        </b-modal>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BCollapse,
  VBToggle,
  VBModal,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import axios from "@/libs/axios";
import axiosDef from "axios";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import HierarchyUsers from "@core/components/HierarchyUsers/HierarchyUsers.vue";
import yearDropdownPlugin from "@/helpers/year_flatpickr_plugin";
import ExportButton from "@/views/pages/export/ExportButton.vue";

export default {
  components: {
    ExportButton,
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    VBModal,
    BCardText,
    VBToggle,
    vSelect,
    VueGoodTable,
    flatPickr,
    ToastificationContent,
    HierarchyUsers,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      fields: [
        { label: "Nro Ticket", key: "referenceId" }, //reference
        { label: "Apuesta Id", key: "transactionID" }, //transactionID
        { label: "Usuario", key: "user[0].username" }, //user_from.userName
        { label: "Fecha Creado", key: "createdAt" }, //createdAt
        { label: "Status", key: "transaction_status" }, //transaction_status
        { label: "Monto", key: "amount" }, //amount
      ],
      typeOptions: [
        { key: "Agente", value: "Agent" },
        { key: "Usuario", value: "Player" },
      ],
      statusOptions: [
        { key: "bet", value: this.$t('status.bet') },
        { key: "return", value: this.$t('status.return') },
        { key: "bet_cancel", value: this.$t('status.bet_cancel') },
        { key: "cashout", value: this.$t('status.cashout')},
        { key: "lose", value: this.$t('status.lose') },
        { key: "win", value: this.$t('status.win') },
        { key: "cancel", value: this.$t('status.cancel') },
        { key: "rollback", value: this.$t('status.rollback') },
      ],
/*       statusOptions: [
        { key: "bet", value: "xxxxxxxxx" },
        
      ], */
      loadingData: false,
      dataItems: [],

      dataIdUser: [],
      pages: [20, 40, 60],
      search: "",
      rows: [],
      serverParams: {
        page: 1,
        limit: 20,
      },
      totalRows: 0,
      customerOptions: [],
      currencyssOptions: [],
      ticketOptions: [],
      filterSelect: {
        transactionId: "",
        raceCourses: "",
        selectStatus: "",
        userType: "",
        transaction_status: "",
        dateFrom: moment()
          .subtract(7, "days")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        dateTo: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      },
      img: "",
      exportFilter: {},
      width: window.innerWidth,
    };
  },

  computed: {
    items() {
      if (this.dataItems != undefined) {
        return this.dataItems.filter((item) => {
          return this.checkUsername(item) || this.checkTicket(item);
        });
      }
    },
  },
  // add filters
  filters: {
    moment: function (date) {
      if (date.includes("UTC")) {
        date = date.replace(" UTC", "");
      }
      return moment(date).format("MM/DD/YYYY HH:mm:ss");
    },
  },

  methods: {
    yearDropdownPlugin,

    checkUsername(item) {
      return item.user_from?.username?.toLowerCase().includes(this.search.toLowerCase());
    },
    checkTicket(item) {
      if (item.reference != "0")
        return item.reference
          ?.toString()
          .toLowerCase()
          .includes(this.search.toLowerCase());
    },
    /*List - Index*/
    async showData({id: userId}) {
      this.dataIdUser = userId;
      try {
        this.loadingData = true;
        const VUE_APP_URL = process.env.VUE_APP_URL;
        let date = {
          dateFrom: new Date(this.filterSelect.dateFrom),
          dateTo: new Date(this.filterSelect.dateTo),
        };
        console.log("EPALE EPALE EPALE", this.filterSelect.statusId)
        const params = {
          whitelabelId: this.$store.state.whitelabelCurrencyNabvar.whitelabel._id,
          transactionId: this.filterSelect.transactionId,
          userId: this.dataIdUser,
          transaction_status: this.filterSelect.transaction_status,
          date: [date.dateFrom.toISOString(), date.dateTo.toISOString()],
          typeUser: this.filterSelect.userType,
        };
        this.exportFilter = params;
        const result = await axios.post(
          `${VUE_APP_URL}/betprime/report/ticket/history`,
          params
        );
        console.log(result, "result")
        this.dataItems = result.data;
        this.Totals = result.data.length;
        this.dataItems.forEach((element) => {
          element.createdAt = element.createdAt + " UTC";
        });
        return result.data;
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching data list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loadingData = false;
      }
    },

    onPageChange(page) {
      console.log(page, "page");
      this.serverParams.page = page;
      this.showData();
    },
    onPerPageChange(limit) {
      this.serverParams.page = 1;
      this.serverParams.limit = limit;
      this.showData();
    },
    inputSelect() {
      let id = this.dataIdUser;

      this.showData({ id });
    },
  },
  created() {
    this.width = window.innerWidth;
    window.addEventListener("resize", () => {
      this.width = window.innerWidth;
    });
  },
  mounted() {
    console.log("this.showoptions", this.statusOptions)
    this.showData();
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}

.scroll::-webkit-scrollbar {
  -webkit-appearance: none;
}

.scroll::-webkit-scrollbar-button:increment,
.scroll::-webkit-scrollbar-button {
  display: none;
}

.scroll::-webkit-scrollbar:horizontal {
  height: 10px;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
}

.scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
